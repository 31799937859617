import { default as Mustache } from "./mustache.js"
import { closeCurrentPopup as searchMapCloseCurrentPopup } from "./search-map.js"


var map;
var markers = [];
var landmarkData;
var fallbackMarkerUrl = '/images/common/map-pin.png';
var currentPopup;
var searchRequests = [];
var Popup;
var currentPopupId;
var pageType = '';
var landmarkSearchUrl = '/api/landmarks';

/**
 * Sets up a map to use landmarks. Can you use on any Google Map
 * @param {google.maps.map} mapInstance Map to display one
 * @param {Popup} popupInstance The Popup class
 * @param {string} pageTypeInstance The page which the landmarks are being displayed. Options are searchPage or propertyPage
 */
function init(mapInstance, popupInstance, pageTypeInstance) {
	map = mapInstance;
	Popup = popupInstance;
	pageType = pageTypeInstance;
	attachListeners();
}

function attachListeners() {

	map.addListener('idle', function() {
		var bounds = map.getBounds();
		var southWest = bounds.getSouthWest();
		var northEast = bounds.getNorthEast();
		getLandmarks(southWest.lat(), northEast.lat(), southWest.lng(), northEast.lng(), map.getZoom());
	});

}

/**
 * Gets the landmarks
 * @param {number} minlatitude Min latitude of the map bounds
 * @param {number} maxlatitude Max latitude of the map bounds
 * @param {number} minlongitude Min longitude of the map bounds
 * @param {number} maxlongitude Max longitude of the map bounds
 * @param {number} zoom The current zoom of the map
 */
function getLandmarks(minlatitude, maxlatitude, minlongitude, maxlongitude, zoom) {

	cancelPreviousRequests();

	var landmarkSearchRequest = $.ajax({
		url: landmarkSearchUrl + '?minlatitude=' + minlatitude + '&maxlatitude=' + maxlatitude + '&minlongitude=' + minlongitude + '&maxlongitude=' + maxlongitude + '&zoom=' + zoom + '&pageType=' + pageType,
		dataType: 'json',
		success: function(response) {
			addMarkers(response);
			landmarkData = response;
		},
		error: function(e) {

			if (e.status === 404) {
				landmarkData = undefined;
				clearMarkersWhichShouldBeHidden([]);
				closeCurrentPopup();
			}

		}
	});

	searchRequests.push(landmarkSearchRequest);
}

function cancelPreviousRequests() {
	for (var i = 0, len = searchRequests.length; i < len; i++) {
		searchRequests[i].abort();
	}
}

/**
 * Adds all markers to the map and clears any old markers
 * @param {array} newLandmarkData Current landmark data
 */
function addMarkers(newLandmarkData) {
	clearMarkersWhichShouldBeHidden(newLandmarkData.landmarks);
	var previousMarkers = getActiveMarkersData();
	for (var i = 0, len = newLandmarkData.landmarks.length; i < len; i++) {
		var currentLandmark = newLandmarkData.landmarks[i];
		if (previousMarkers && previousMarkers.length) {
			if (!isMarkerInArray(previousMarkers, currentLandmark.id)) {
				addMarker(currentLandmark, newLandmarkData);
			}
		} else {
			addMarker(currentLandmark, newLandmarkData);
		}
	}

	// check if overlay should show, as the marker my now be hidden
	checkIfOverlayShouldShow(newLandmarkData);
}

function getActiveMarkersData() {
	var markersData = [];
	for (var i = 0, len = markers.length; i < len; i++) {
		markersData.push(markers[i].landmarkData);
	}
	return markersData;
}

/**
 * Checks if the marker is already displayed on the map
 * @param {array} landmarks Array of landmarks
 * @param {number} id Id of the landmark
 * @returns {boolean} Boolean
 */
function isMarkerInArray(landmarks, id) {
	for (var i = 0, len = landmarks.length; i < len; i++) {
		if (landmarks[i].id === id) {
			return true;
		}
	}
	return false;
}

function addMarker(markerData, landmarkData) {
	var marker = new google.maps.Marker({
		position: {
			lat: markerData.latitude,
			lng: markerData.longitude
		},
		map: map,
		landmarkData: markerData
	});

	setMarkerIcon(marker, landmarkData.types, false);

	marker.addListener('click', function() {

		closeCurrentPopup(true);
		var template = document.getElementById('landmarkMapOverlay').innerHTML;
		var templated = Mustache.render(template, markerData);
		currentPopup = new Popup({
			lat: markerData.latitude,
			lng: markerData.longitude
		}, templated, map, "landmark");
		currentPopup.setMap(map);

		// change the icon to the active one
		setMarkerIcon(marker, landmarkData.types, true);
		marker.setZIndex(3);
		currentPopupId = markerData.id;

	});

	markers.push(marker);
}

/**
 * Sets the marker icon
 * @param {google.maps.Marker} marker Google map marker
 * @param {array} landmarkData Array of landmarks
 * @param {any} isActive Display the active pin or not
 */
function setMarkerIcon(marker, landmarkData, isActive) {

	marker.setIcon({
		url: getIconUrlForType(marker.landmarkData.type, landmarkData, isActive),
		size: new google.maps.Size(29, 44),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(14.5, 44),
		scaledSize: new google.maps.Size(29, 44)
	});

}

/**
 * Closes the current popups
 * @param {boolean} closeSearchMapPopups Whether the search map popups should also be closed
 */
function closeCurrentPopup(closeSearchMapPopups) {
	if (currentPopup) {
		currentPopup.setMap(null);
		currentPopup = undefined;
		currentPopupId = undefined;
		// reset markers to inactive
		for (var i = 0, len = markers.length; i < len; i++) {
			setMarkerIcon(markers[i], landmarkData.types, false);
			markers[i].setZIndex(2);
		}
	}
	if (closeSearchMapPopups) {
		searchMapCloseCurrentPopup(true);
	}
}

/**
 * Clears landmarks which are not in the latest data
 * @param {array} newLandmarks Landmarks
 */
function clearMarkersWhichShouldBeHidden(newLandmarks) {
	var markersToKeep = [];
	for (var i = 0, len = markers.length; i < len; i++) {
		if (newLandmarks.length === 0) {
			markers[i].setMap(null);
			continue;
		}
		if (!isMarkerInArray(newLandmarks, markers[i].landmarkData.id)) {
			markers[i].setMap(null);
		} else {
			markersToKeep.push(markers[i]);
		}
	}

	markers = markersToKeep;

}

/**
 * Gets the marker icon for a landmark type
 * @param {string} landmarkType The landmark type
 * @param {array} landmarkTypes Landmark types
 * @param {boolean} isActive Display the active marker or not
 * @return {string} The url of the marker
 */
function getIconUrlForType(landmarkType, landmarkTypes, isActive) {
	if (!landmarkTypes) {
		return fallbackMarkerUrl;
	}
	for (var i = 0, len = landmarkTypes.length; i < len; i++) {
		var currentLandmark = landmarkTypes[i];
		if (currentLandmark.name === landmarkType) {
			return isActive ? currentLandmark.iconActiveUrl : currentLandmark.iconUrl;
		}
	}
	return fallbackMarkerUrl;
}

/**
 * Determines if an overlay should still show e.g. when zooming out
 * @param {array} newLandmarkData Current landmark data
 */
function checkIfOverlayShouldShow(newLandmarkData) {
	if (!currentPopupId) {
		return;
	}
	var popupMarkerStillVisible = false;
	for (var i = 0, len = newLandmarkData.landmarks.length; i < len; i++) {
		if (newLandmarkData.landmarks[i].id === currentPopupId) {
			popupMarkerStillVisible = true;
		}
	}

	if (!popupMarkerStillVisible) {
		closeCurrentPopup();
	}
}

function currentPopupIsLandmark() {
	if (currentPopup && currentPopup.popupType === "landmark") {
		return true;
	}
	return false;
}

export default init;
export { closeCurrentPopup, currentPopupIsLandmark }
